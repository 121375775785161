import React from "react";
import { observer } from "mobx-react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { ThemeModeSwitcher } from "../../../partials";
import { Notification } from "../../../partials/";
import stores from "../../../app/stores";
import { KTSVG } from "../../../helpers";
import i18n from "../../../i18n";

const Topbar: React.FC = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();

  const totalFreeTokenCount = process.env.REACT_APP_FREE_TOKEN_COUNT
    ? parseInt(process.env.REACT_APP_FREE_TOKEN_COUNT)
    : 25000;
  const usedTokenCount =
    totalFreeTokenCount - stores.companyStore.freeTokenCount;

  const progress = Math.round((100 * usedTokenCount) / totalFreeTokenCount);

  const color =
    progress < 50 ? "primary" : progress > 75 ? "danger" : "warning";

  const createNewSectionClick = () => {
    const selectedUserCompany = stores.companyStore.selectedUserCompany;

    if (selectedUserCompany && !selectedUserCompany.is_chief_available) {
      toast.error(i18n.ToastMessages.contactUsForFeature, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      navigate("/create-new-section-flow");
    }
  };

  return (
    <div className="d-flex flex-shrink-0 align-items-center">
      {location.pathname === "/the-chief-workspace" && (
        <div className="d-flex ms-3">
          <button
            onClick={createNewSectionClick}
            className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6"
          >
            <i className="ki-duotone ki-plus fs-2 text-primary me-0 me-md-2"></i>
            <span className="d-none d-md-inline">New Section</span>
          </button>
        </div>
      )}

      {stores.userStore.isCurrentUserAdmin && stores.userStore.isFreeUser ? (
        <div className="d-flex ms-2">
          <button
            className="btn btn-flex flex-center btn-color-gray-700 btn-active-color-primary h-40px px-0 px-md-6"
            style={{
              width: 300,
            }}
            onClick={() => {}}
          >
            <KTSVG
              path={"/media/icons/duotune/abstract/abs021.svg"}
              className={`svg-icon svg-icon-2x text-${color} me-3`}
            />
            <div className="d-flex flex-column w-100 ms-2">
              <div className="d-flex mb-2">
                <span
                  className={`d-flex text-${color} me-2 fs-7 fw-semibold`}
                  style={{ flex: 1 }}
                >
                  Free Token Usage
                </span>
                <span className="text-muted me-2 fs-8 fw-semibold">{`${progress}%`}</span>
              </div>
              <div className="progress h-6px w-100">
                <div
                  className={`progress-bar bg-${color}`}
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </button>
        </div>
      ) : null}

      {/* Notification Component */}
      <Notification />

      {/* Theme mode switcher */}
      <ThemeModeSwitcher toggleBtnClass="flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px" />
    </div>
  );
});

export { Topbar };
